import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // const requestUrl = `https://sea-turtle-app-k5gi2.ondigitalocean.app/api/login`; // Replace with your actual API endpoint
      const requestUrl = `https://sea-turtle-app-k5gi2.ondigitalocean.app/api/login`; 
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }
      const { token, role } = await response.json();
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      localStorage.setItem('isAuthenticated', 'true');
      onLogin(); 

      // Redirect based on user role
      if (role === '1') { 
        navigate('IVMS'); // Redirect to admin dashboard
      } else if (role === '2') {
        navigate('/'); // Redirect to user dashboard
      } else {
        navigate('/'); // Default redirection
      }
    } catch (error) {
      setErrorMessage(error.message || 'Login failed');
    }
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body">
            <center>
              <img src={`${process.env.PUBLIC_URL}/logo/Logo.png`} alt="Logo" /><br />
            </center>
            <p className="login-box-msg">Sign in to start your session</p>
            {errorMessage && (
              <div className="alert alert-info d-flex align-items-center" role="alert">
                <i className="fas fa-info-circle mr-2"></i>
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleLogin}>
              <div className="input-group mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Username" 
                  value={username}
                  onChange={(e) => setUsername(e.target.value)} 
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input 
                  type="password" 
                  className="form-control" 
                  placeholder="Password" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} 
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
